import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReCAPTCHA from "react-google-recaptcha";
import app from "../app.json";

const RegisterPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(1); // 1: Register, 2: OTP Verification
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // Handle Registration
  const handleSignup = async () => {
    if (!email || !name || !password || !confirmPassword) {
      setError("Please fill in all fields.");
      return;
    }
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    if(!value){
      setError("Please verify you are not a robot.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${app.auth_service}/auth/signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, name, password, username: email }),
      });
      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        setStep(2); // Move to OTP verification step
        setError("");
      } else {
        setError(data.error || "Signup failed. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      setError("Signup request failed. Please try again.");
    }
  };

  // Handle OTP Verification
  const handleOtpVerification = async () => {
    if (otp.length !== 6) {
      setError("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${app.auth_service}/auth/confirm-signup`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: email, code: otp }),
      });
      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        await registerUserInDatabase(data);
        navigate("/login");
      } else {
        setError(data.error || "OTP verification failed.");
      }
    } catch (error) {
      setLoading(false);
      setError("OTP verification failed. Please try again.");
    }
  };

  // Register user details in the backend database
  const registerUserInDatabase = async (userData) => {
    try {
      const response = await fetch(`${app.API_URL}/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to register user in the database.");
      }
    } catch (error) {
      setError("Failed to save user details. Please contact support.");
    }
  };
  const handleRegister = () => {
    navigate("/LoginPage");
  };
  function onChange(value) {
    setValue(value);
  }
  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center overflow-hidden">
      <div className="flex flex-col items-center  w-full max-w-md px-6 py-8 mx-auto lg:py-0">
        <a href="#" className="flex items-center mt-32 text-3xl font-semibold text-gray-900 dark:text-white">
          <img
            className="w-10 h-10 mr-2"
            src="https://echowinbucket.s3.ap-south-2.amazonaws.com/android-chrome-512x512.png"
            alt="logo"
          />
          WealthTrail by Echowin
        </a>
        <div className="w-full mt-12 bg-white rounded-lg shadow dark:border max-w-2xl xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-10 space-y-6">
            <h1 className="text-2xl font-semibold leading-tight tracking-tight text-center text-gray-900 dark:text-white">
              {step === 1 ? "Register" : "Verify OTP"}
            </h1>

            {step === 1 ? (
              <>
                <Carousel autoPlay infiniteLoop showThumbs={false} className="mb-4">
                  {/* Carousel slides */}
                </Carousel>
                <div>
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your name"
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your email"
                  />
                </div>
                <div>
                  <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Enter your password"
                  />
                </div>
                <div>
                  <label htmlFor="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Confirm your password"
                  />
                </div>
                <ReCAPTCHA
                  sitekey={app.KEY}
                  onChange={onChange}
                />
              </>
            ) : (
              <div>
                <label htmlFor="otp" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Enter OTP
                </label>
                <input
                  type="text"
                  id="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-96 p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter the OTP sent to your email"
                />
              </div>
            )}

            {error && <p className="mt-2 text-sm text-red-500">{error}</p>}

            <button
              onClick={step === 1 ? handleSignup : handleOtpVerification}
              className="flex w-full justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={loading}
            >
              {loading ? "Processing..." : step === 1 ? "Sign Up" : "Verify OTP"}
            </button>

            <div className="mt-2">
              <a
                href="#"
                onClick={handleRegister}
                className="text-xs text-blue-500 hover:underline"
              >
                back to login
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterPage;
