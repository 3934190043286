import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { AuthContext } from '../context/AuthContext';
import app from '../app.json';

const Dashboard = () => {
  const [options, setOptions] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [previousPassword, setPreviousPassword] = useState('');
  const [proposedPassword, setProposedPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const { user, name } = useContext(AuthContext); 
  const handleLogout = async () => {
    try {
      if (currentUser) {
        await fetch(`${app.auth_service}/auth/logout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accessToken: currentUser }),
        });
      }
      logout();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Fetch dashboard options
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(`${app.API_URL}/dash-opts`, {
          headers: {
            Authorization: `Bearer ${app.JWT_AUTH}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Failed to fetch options');
        const data = await response.json();
        setOptions(data);
      } catch (error) {
        console.error('Error fetching dashboard options:', error);
      }
    };

    fetchOptions();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setSuccessMessage('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPreviousPassword('');
    setProposedPassword('');
    setSuccessMessage('');
  };

  const handleChangePassword = async () => {
    try {
      const response = await fetch(
        'http://ec2-18-61-76-230.ap-south-2.compute.amazonaws.com:3000/auth/change-password',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            accessToken: currentUser,
            previousPassword,
            proposedPassword,
          }),
        }
      );

      if (!response.ok) throw new Error('Failed to change password');
      const result = await response.json();
      setSuccessMessage('Password successfully changed!');
      setTimeout(closeModal, 2000); // Close the modal after 2 seconds
    } catch (error) {
      console.error('Error changing password:', error);
      setSuccessMessage('Failed to change password. Try again.');
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="flex justify-between items-center p-4 bg-white shadow-md">
        <div>
          <h1 className="text-3xl font-bold">Hello {sessionStorage.getItem('name')},</h1>
          <p className="text-lg">
            Welcome to <span className="text-yellow-500 font-bold">WealthTrail</span> by Echowin
          </p>
        </div>
        <div>
          {/* User Profile Button */}
          <button
            onClick={toggleSidebar}
            className="p-2 bg-gray-800 text-white rounded-full"
          >
            <i className="fi fi-rr-user"></i> 👨‍💼
          </button>
        </div>
      </div>

      {/* Sidebar */}
      <div
  className={`fixed top-0 right-0 h-full bg-white shadow-lg transform ${
    isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
  } transition-transform duration-300 w-160 z-50`}
>
  <div className="flex flex-col h-full">
    <div className="flex justify-between items-center p-4 border-b">
      <h2 className="text-xl font-bold">User Profile</h2>
      <button onClick={toggleSidebar} className="text-gray-500">
        <i className="fi fi-rr-cross"></i> Close
      </button>
    </div>

    <div className="p-4">
      <p className="text-lg font-semibold">Name: {sessionStorage.getItem('name')}</p>
      <p className="text-lg">Email: {sessionStorage.getItem('email')}</p>
    </div>

    <div className="mt-auto p-4 space-y-4">
      <button
        onClick={openModal}
        className="w-full bg-blue-600 text-white py-2 rounded-lg"
      >
        Change Password
      </button>
      <button
        onClick={handleLogout}
        className="w-full bg-red-600 text-white py-2 rounded-lg"
      >
        Logout
      </button>
    </div>
  </div>
</div>


      {/* Change Password Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-96 shadow-lg">
            <h2 className="text-xl font-bold mb-4">Change Password</h2>
            <div className="space-y-4">
              <input
                type="password"
                placeholder="Previous Password"
                value={previousPassword}
                onChange={(e) => setPreviousPassword(e.target.value)}
                className="w-full border px-3 py-2 rounded-lg"
              />
              <input
                type="password"
                placeholder="New Password"
                value={proposedPassword}
                onChange={(e) => setProposedPassword(e.target.value)}
                className="w-full border px-3 py-2 rounded-lg"
              />
            </div>
            <div className="mt-4 flex justify-end gap-2">
              <button
                onClick={closeModal}
                className="bg-gray-300 px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleChangePassword}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Submit
              </button>
            </div>
            {successMessage && (
              <p className="mt-4 text-center text-green-600 font-semibold">
                {successMessage}
              </p>
            )}
          </div>
        </div>
      )}

      {/* Dashboard Options */}
      <div className="flex flex-col items-center mt-12 gap-4">
        {options.map((option) => (
          <div
            key={option.id}
            className={`flex items-center justify-center w-11/12 md:w-1/2 lg:w-1/3 ${
              option.name === 'My Docs' ? 'bg-blue-800' : 'bg-gray-400'
            } text-white text-lg rounded-lg shadow-lg p-4 gap-4 transition-all duration-200 ${
              option.name === 'My Docs' ? 'cursor-pointer hover:bg-blue-700' : 'cursor-not-allowed'
            }`}
            onClick={() => {
              if (option.name === 'My Docs') {
                navigate('/myDocs');
              }
            }}
          >
            <img
              src={option.icon_url}
              alt={option.name}
              className="w-32 h-32 object-contain"
            />
            <p className="font-medium">{option.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
