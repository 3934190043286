import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import app from "../app.json";
import { useAuth } from "../context/AuthContext";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [mode, setMode] = useState("login"); // Modes: login, forgotPassword, setPassword
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async () => {
    if (username && password) {
      try {
        setLoginLoading(true);
        const response = await fetch(`${app.auth_service}/auth/login`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username, password }),
        });
        const data = await response.json();
        setLoginLoading(false);

        if (response.ok) {
          login(data);
          const verifyResponse = await fetch(`${app.API_URL}/verify-user`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.accessToken}`,
            },
            body: JSON.stringify({ uid: data.userId }),
          });

          if (verifyResponse.ok) {
            navigate("/dashboard");
          } else {
            setError("User verification failed.");
          }
        } else {
          setError(data.error || "Login failed");
        }
      } catch (error) {
        setLoginLoading(false);
        setError("Login request failed");
        console.error(error);
      }
    } else {
      setError("Please enter Username and Password");
    }
  };

  const [slides, setSlides] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0); // To track the current slide index

  // Fetch slides data from API
  const handleCarousel = async () => {
    try {
      const response = await fetch(`${app.API_URL}/login-slides`);
      if (response.ok) {
        const data = await response.json();
        setSlides(data);
      } else {
        console.error("Failed to fetch slides");
      }
    } catch (error) {
      console.error("Error fetching slides:", error);
    }
  };

  // Load slides on component mount
  useEffect(() => {
    handleCarousel();
  }, []);

  // Update the current slide index when the carousel changes
  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  const handleForgotPassword = async () => {
    if (username) {
      try {
        setLoginLoading(true);
        const response = await fetch(
          "http://ec2-18-61-76-230.ap-south-2.compute.amazonaws.com:3000/auth/forget-password",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username }),
          }
        );
        setLoginLoading(false);

        if (response.ok) {
          setError("OTP sent to your email");
          setMode("setPassword");
        } else {
          const data = await response.json();
          setError(data.error || "Failed to send OTP");
        }
      } catch (error) {
        setLoginLoading(false);
        setError("Request failed");
        console.error(error);
      }
    } else {
      setError("Please enter your email");
    }
  };

  const handleSetPassword = async () => {
    if (username && otp && newPassword) {
      try {
        setLoginLoading(true);
        const response = await fetch(
          "http://ec2-18-61-76-230.ap-south-2.compute.amazonaws.com:3000/auth/confirm-forgot-password",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              username,
              confirmationCode: otp,
              newPassword,
            }),
          }
        );
        setLoginLoading(false);

        if (response.ok) {
          setError("Password reset successful! Please log in.");
          setMode("login");
        } else {
          const data = await response.json();
          setError(data.error || "Failed to reset password");
        }
      } catch (error) {
        setLoginLoading(false);
        setError("Request failed");
        console.error(error);
      }
    } else {
      setError("All fields are required");
    }
  };

  const handleRegister = () => {
    navigate("/registerUser");
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-screen flex items-center justify-center overflow-hidden">
      <div className="flex flex-col items-center w-full max-w-md px-6 py-8 mx-auto">
        <a
          href="#"
          className="flex items-center text-3xl font-semibold text-gray-900 dark:text-white mb-4"
        >
          <img
            className="w-10 h-10 mr-2"
            src="https://echowinbucket.s3.ap-south-2.amazonaws.com/android-chrome-512x512.png"
            alt="logo"
          />
          WealthTrail By Echowin
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6">
            <h1 className="text-2xl text-center font-semibold text-gray-900 md:text-2xl dark:text-white">
              {mode === "login"
                ? "Sign In"
                : mode === "forgotPassword"
                ? "Forgot Password"
                : "Reset Password"}
            </h1>
            <div className="text-center mb-4">
      {/* Title */}
      {slides.length > 0 && (
        <h2 className="text-xl text-left rtl:text-right text-gray-400 font-bold">{slides[currentSlide]?.title}</h2>
      )}
{/* Subtitle */}
{slides.length > 0 && (
        <p className="text-sm text-left rtl:text-right text-white">{slides[currentSlide]?.message}</p>
      )}

      {/* Carousel */}
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        onChange={handleSlideChange}
        className="mb-2"
      >
        {slides.map((slide) => (
          <div key={slide.id} className="relative h-64">
            <img
              
              src={slide.image_url}
              alt={slide.title}
              className="object-contain w-full h-full"
            />
          </div>
        ))}
      </Carousel>
    </div>

            {mode === "login" && (
              <>
                <div>
                  <label
                    htmlFor="username"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Enter Registered Email"
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Enter password"
                  />
                </div>
                
                <div >
                <a className="text-s text-white">
                Can't access your account? <span href="#" onClick={() => setMode("forgotPassword")} className="text-s text-blue-500 hover:underline"> click here!</span>
                </a>
                </div>
                
                <button
                  onClick={handleLogin}
                  className="w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500"
                  disabled={loginLoading}
                >
                  {loginLoading ? "Logging in..." : "Sign In"}
                </button>

                <button
                  onClick={handleRegister}
                  className="w-full mt-4 rounded-md bg-gray-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-gray-500"
                >
                  Sign Up
                </button>

                
              </>
            )}

            {mode === "forgotPassword" && (
              <>
                <div>
                  <label
                    htmlFor="username"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Enter Registered Email"
                  />
                </div>

                <button
                  onClick={handleForgotPassword}
                  className="w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500"
                  disabled={loginLoading}
                >
                  {loginLoading ? "Sending OTP..." : "Send OTP"}
                </button>

                <div className="mt-2">
                  <a
                    href="#"
                    onClick={() => setMode("login")}
                    className="text-xs text-blue-500 hover:underline"
                  >
                    back to login
                  </a>
                </div>
              </>
            )}

            {mode === "setPassword" && (
              <>
                <div>
                  <label
                    htmlFor="username"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Enter Registered Email"
                  />
                </div>

                <div>
                  <label
                    htmlFor="otp"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    OTP
                  </label>
                  <input
                    type="text"
                    id="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Enter OTP"
                  />
                </div>

                <div>
                  <label
                    htmlFor="newPassword"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Enter New Password"
                  />
                </div>

                <button
                  onClick={handleSetPassword}
                  className="w-full rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white hover:bg-indigo-500"
                  disabled={loginLoading}
                >
                  {loginLoading ? "Setting Password..." : "Done"}
                </button>

                <div className="mt-2">
                  <a
                    href="#"
                    onClick={() => setMode("login")}
                    className="text-xs text-blue-500 hover:underline"
                  >
                    Back to Login
                  </a>
                </div>
              </>
            )}

            {error && <p className="text-red-500 text-sm">{error}</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
